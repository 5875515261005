import React from 'react'

const Logo = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='spring'
      role='img'
      className='icon-spring'
      viewBox='0 0 400 400'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Spring Cloud Dataflow UI</title>
      <g>
        <path
          fill='currentColor'
          d='M187.3,60.9c49.9,0,96,34.9,107.2,81.2l1.3,5.3l5.2,1.5c28.6,8,48.6,34.5,48.6,64.3c0,17.7-6.9,34.3-19.5,46.7
        c-12.5,12.4-29.3,19.2-47.1,19.2h-195c-43.6,0-77.8-33.5-77.8-76.2c0-39.9,30.5-72.6,71.1-76.1l5.5-0.5l2.2-5.1
        C104.7,85.7,145.1,60.9,187.3,60.9 M187.3,51.6c-46,0-89.9,27.5-106.8,65.9C36,121.3,1,157.3,1,202.9c0,48.1,39,85.5,87.1,85.5
        c17.1,0,179.1,0,195,0c41.9,0,75.9-33.2,75.9-75.1c0-34.8-23.5-64.3-55.4-73.3C290.9,87.7,239.9,51.6,187.3,51.6L187.3,51.6z'
        />
        <path
          fill='currentColor'
          d='M114.3,234.9c3.5,0,5.2,0.4,6.5,1.7c1.9,1.7,3.4,5,3.4,14.2c0,9.1-1.5,12.4-3.4,14.2c-1.4,1.2-3,1.7-6.5,1.7h-7.4
        c-3.5,0-5.1-0.5-6.5-1.7c-1.9-1.7-3.4-5-3.4-14.2c0-9.2,1.5-12.5,3.4-14.2c1.4-1.3,3-1.7,6.5-1.7H114.3z M108.5,240.7
        c-1.7,0-2.2,0.2-2.6,0.5c-0.5,0.5-1.2,1.7-1.2,9.5c0,7.8,0.7,9.1,1.2,9.5c0.4,0.4,0.9,0.5,2.6,0.5h4.1c1.7,0,2.2-0.1,2.6-0.5
        c0.5-0.4,1.2-1.6,1.2-9.5c0-7.8-0.7-9.1-1.2-9.5c-0.4-0.3-0.9-0.5-2.6-0.5H108.5z'
        />
        <path
          fill='currentColor'
          d='M142.2,259.7v-22.4l-4.5,1.5l-1.6-4.8l7.5-2.8h5.2v28.4H142.2z'
        />
        <path
          fill='currentColor'
          d='M177.2,224.8c2.6,0,3.9,0.3,4.9,1.3c1.4,1.3,2.6,3.7,2.6,10.7c0,6.9-1.2,9.4-2.6,10.7c-1,0.9-2.3,1.3-4.9,1.3h-5.6
        c-2.6,0-3.8-0.3-4.9-1.3c-1.4-1.3-2.6-3.8-2.6-10.7c0-6.9,1.1-9.4,2.6-10.7c1-1,2.2-1.3,4.9-1.3H177.2z M172.9,229.2
        c-1.3,0-1.7,0.1-1.9,0.4c-0.4,0.3-0.9,1.3-0.9,7.2c0,5.9,0.5,6.8,0.9,7.1c0.3,0.3,0.7,0.4,1.9,0.4h3.1c1.3,0,1.7-0.1,2-0.4
        c0.4-0.3,0.9-1.2,0.9-7.1c0-5.9-0.5-6.8-0.9-7.2c-0.3-0.2-0.7-0.4-2-0.4H172.9z'
        />
        <path
          fill='currentColor'
          d='M199,237.1v-16.9l-3.4,1.2l-1.2-3.6l5.7-2.1h3.9v21.5H199z'
        />
        <path
          fill='currentColor'
          d='M218.7,224.9v-15.6l-3.1,1.1l-1.1-3.3l5.2-1.9h3.6v19.8H218.7z'
        />
        <path
          fill='currentColor'
          d='M239.6,192.7c1.7,0,2.5,0.2,3.2,0.8c0.9,0.8,1.7,2.4,1.7,6.9c0,4.5-0.8,6.1-1.7,6.9c-0.7,0.6-1.5,0.8-3.2,0.8H236
        c-1.7,0-2.5-0.2-3.2-0.8c-0.9-0.8-1.7-2.5-1.7-6.9c0-4.5,0.7-6.1,1.7-6.9c0.7-0.6,1.5-0.8,3.2-0.8H239.6z M236.8,195.5
        c-0.8,0-1.1,0.1-1.3,0.2c-0.2,0.2-0.6,0.8-0.6,4.7c0,3.8,0.4,4.4,0.6,4.6c0.2,0.2,0.4,0.2,1.3,0.2h2c0.8,0,1.1-0.1,1.3-0.2
        c0.2-0.2,0.6-0.8,0.6-4.6c0-3.8-0.4-4.4-0.6-4.7c-0.2-0.2-0.4-0.2-1.3-0.2H236.8z'
        />
        <path
          fill='currentColor'
          d='M253.7,177.6c1.4,0,2.1,0.2,2.6,0.7c0.8,0.7,1.4,2,1.4,5.7c0,3.7-0.6,5-1.4,5.7c-0.6,0.5-1.2,0.7-2.6,0.7h-3
        c-1.4,0-2.1-0.2-2.6-0.7c-0.8-0.7-1.4-2-1.4-5.7c0-3.7,0.6-5.1,1.4-5.7c0.6-0.5,1.2-0.7,2.6-0.7H253.7z M251.4,180
        c-0.7,0-0.9,0.1-1,0.2c-0.2,0.2-0.5,0.7-0.5,3.9c0,3.2,0.3,3.7,0.5,3.8c0.1,0.1,0.4,0.2,1,0.2h1.7c0.7,0,0.9-0.1,1.1-0.2
        c0.2-0.2,0.5-0.7,0.5-3.8c0-3.2-0.3-3.7-0.5-3.9c-0.2-0.1-0.4-0.2-1.1-0.2H251.4z'
        />
        <path
          fill='currentColor'
          d='M263.9,163.7c1,0,1.5,0.1,1.9,0.5c0.5,0.5,1,1.5,1,4.2c0,2.7-0.5,3.7-1,4.2c-0.4,0.4-0.9,0.5-1.9,0.5h-2.2
        c-1,0-1.5-0.1-1.9-0.5c-0.6-0.5-1-1.5-1-4.2c0-2.7,0.4-3.7,1-4.2c0.4-0.4,0.9-0.5,1.9-0.5H263.9z M262.2,165.4
        c-0.5,0-0.7,0.1-0.8,0.1c-0.1,0.1-0.4,0.5-0.4,2.8c0,2.3,0.2,2.7,0.4,2.8c0.1,0.1,0.3,0.1,0.8,0.1h1.2c0.5,0,0.7,0,0.8-0.1
        c0.1-0.1,0.4-0.5,0.4-2.8c0-2.3-0.2-2.7-0.4-2.8c-0.1-0.1-0.3-0.1-0.8-0.1H262.2z'
        />
        <path
          fill='currentColor'
          d='M286.8,65.8c-10.3,29.8-46.3,53.1-75.6,60.1c-27.4,6.5-51.6,1.2-77,10.9c-49.1,18.9-52.3,65-35.8,91.8
        c1.3,2.1,65.1-3,96.4-16.4c41.7-17.9,66.6-51.6,73.1-64.2c0.5-0.5,2.1-1.9,4.6-1.1c2.6,0.8,2.8,3,2.7,3.7
        c-2.8,13.1-15,58.1-63.5,86.7c-22,13-38.9,19.4-51.3,22.8c-2.1,0.6-2,2.1,0.4,2.1c30.2-0.2,99.1,4.2,127.8-32.3
        C322,185.7,304.4,116,286.8,65.8z'
        />
      </g>
    </svg>
  )
}

export default Logo
