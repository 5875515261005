module.exports = [{
      plugin: require('../plugins/spring-remark-download/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/spring-remark-question/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"withWebp":true,"linkImagesToOriginal":false,"quality":80,"maxWidth":800},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/spring-remark-code/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/spring-remark-tabs/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"spring-cloud-data-flow","short_name":"scdf","start_url":"/","background_color":"#45968f","theme_color":"#45968f","display":"minimal-ui","icon":"src/images/dataflow-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"GTM-KZM7GF6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
